import React from 'react';
import './PageNotFound.css';

const NotFoundPage = () => {
  return( 
  <div className="pageNotFound-main-container">
	<div className="pageNotFound-container">
		<h1>404 Page NotFound</h1>
	</div>
  </div>
  );
}

export default NotFoundPage;