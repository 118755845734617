import React, { useState } from 'react';
import FormChange from './form_change';
import '../../../assets/css/agency.min.css';

function Profile() {

  return (
    <>      
    <FormChange value />
    </>
  );
}

export default Profile;
