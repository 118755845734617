import React from "react";
import { Link } from 'react-router-dom';
import "./../../../../assets/css/agency.min.css";
import Footer from './../../footer';
import {Image} from 'react-bootstrap';
import competition_hall from "./../../../../assets/img/competition_hall.jpg";


function Competition_hall() {

  return (
    <div className="Competition_hall">
  

    </div>
  );
}

export default Competition_hall;